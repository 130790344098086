import React from "react"
import "./styles.css"
import { Link } from "gatsby"
import { useTranslation } from "../../utils"

const ScoringBanner = ({ withShadow = true }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="scoring-banner">
        {withShadow && <div className="scoring-banner__shadow" />}
        <h2 className="scoring-banner__title">{t("Want more?")}</h2>
        <span className="scoring-banner__text">
          {t(
            "For more information about protecting your site, please register."
          )}
        </span>
        <div className="wt-flex wt-flex-middle">
          <Link to="/pricing" className="wt-button wt-button--ghost">
            {t("Sign up")}
          </Link>
          <a href="/cabinet/sign-in">{t("or Sign in")}</a>
        </div>
      </div>
    </>
  )
}

export default ScoringBanner
