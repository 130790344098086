import React, { useEffect, useState } from "react"
import "./styles.css"
import classNames from "classnames"
import FakeLoader from "../fake-loader"
import { useInterval, useTranslation } from "../../utils"

const ScoringLoader = ({ isFinish }) => {
  const { t } = useTranslation()
  const [step, setStep] = useState(1)

  useEffect(() => {
    if (isFinish) {
      setStep(4)
    }
  }, [isFinish])

  useInterval(
    () => {
      setStep(step + 1)
    },
    isFinish ? null : 2000
  )

  return (
    <div className="scoring-form">
      <div className="scoring-form__hint">{t("Testing process")}</div>

      <div className="scoring-form__text wt-margin-bottom-60">
        <div className="scoring-form__text-row">
          <span
            className={classNames({ "scoring-form__text--green": step >= 1 })}
          >
            {t("CMS security analysis")}
          </span>
          <span
            className={classNames({ "scoring-form__text--green": step >= 2 })}
          >
            {t("Cookies analysis")}
          </span>
        </div>
        <div className="scoring-form__text-row">
          <span
            className={classNames({ "scoring-form__text--green": step >= 3 })}
          >
            {t("HTTP Headers analysis")}
          </span>
          <span
            className={classNames({ "scoring-form__text--green": step >= 4 })}
          >
            {t("Content security policy analysis")}
          </span>
        </div>
      </div>

      <FakeLoader isFinish={isFinish} />
    </div>
  )
}

export default ScoringLoader
