import axios from "../axios"

export const getScore = async (url, email, locale) => {
  return await axios
    .get(
      "/site/score?&url=" +
        url +
        "&email=" +
        email +
        "&language=" +
        locale.toUpperCase()
    )
    .catch(() => {
      const data = {
        errorText:
          "Something went wrong. Contact administrators please (info@wtotem.com)",
      }
      return { data }
    })
}

export const getWpScore = async url => {
  return await axios.get("/wp/scan?url=" + url).catch(() => {
    const data = {
      errorText:
        "Something went wrong. Contact administrators please (info@wtotem.com)",
    }
    return { data }
  })
}
