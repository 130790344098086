import React from "react"
import { useTranslation } from "../../utils"

const Methodology = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <div className="scoring-table__methodology">
      <h2>{t("Scoring Methodology")}</h2>

      <ul>
        <li>
          {t(
            "The scoring percentage characterizes the number of not detected or discovered security errors."
          )}
        </li>
        <li>
          {t(
            "The rating for a perfectly securely configured web resource is 100%."
          )}
        </li>
        <li>
          {t(
            "Points are deducted for each insecure, incomplete, or unreliable configuration of your website or web server."
          )}
        </li>
      </ul>

      <div className="scoring-table__methodology-points">
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--green">
            A+
          </div>
          {t("Score is")} 100
        </div>
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--green">
            A
          </div>
          {t("Score between")} 90 - 99
        </div>
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--green">
            A-
          </div>
          {t("Score between")} 80 - 89
        </div>

        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--yellow-2">
            B+
          </div>
          {t("Score between")} 70 - 79
        </div>
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--yellow-2">
            B
          </div>
          {t("Score between")} 60 - 69
        </div>
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--yellow-2">
            B-
          </div>
          {t("Score between")} 50 - 59
        </div>

        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--red">
            C+
          </div>
          {t("Score between")} 35 - 49
        </div>
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--red">
            C
          </div>
          {t("Score between")} 20 - 34
        </div>
        <div className="wt-flex wt-flex-middle">
          <div className="scoring-table__card-badge scoring-table__card-badge--red">
            C-
          </div>
          {t("Score lower")} 20
        </div>
      </div>

      <button
        className="wt-button wt-width-1-1 wt-margin-top-15"
        onClick={onClose}
      >
        {t("Close")}
      </button>
    </div>
  )
}

export default Methodology
