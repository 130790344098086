import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./styles.css"

const ScoringError = ({ text, className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "wrong.png" }) {
        childImageSharp {
          fluid(maxWidth: 338) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={`scoring-table__error ${className}`}>
      <div className="scoring-table__error-image">
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </div>
      <h1 className="scoring-table__error-text">{text}</h1>
    </div>
  )
}

export default ScoringError
