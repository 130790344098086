import React, { useState } from "react"
import { useInterval, useTranslation } from "../../utils"
import "./styles.css"

const FakeLoader = ({ isFinish }) => {
  const { t } = useTranslation()
  const [progress, setProgress] = useState(0)
  const [progressLeft, setProgressLeft] = useState(98)
  const splitMilliseconds = 3000
  const fillUpToPercentage = 98
  const stepMilliseconds = 5

  useInterval(
    () => {
      if (progress < 99) {
        setProgress(progress + 1)
      }
    },
    isFinish ? 10 : null
  )

  useInterval(
    () => {
      setProgress(
        progress + progressLeft / 2 / (splitMilliseconds / stepMilliseconds)
      )

      if (progress >= fillUpToPercentage - progressLeft / 2) {
        setProgressLeft(progressLeft / 2)
      }
    },
    isFinish ? null : stepMilliseconds
  )

  return (
    <div className="loader">
      <div className="wt-flex wt-flex-between wt-margin-bottom-20">
        <div className="loader-title">{t("Loading")}</div>
        <div className="loader-indicator">{Math.round(progress)}%</div>
      </div>
      <div className="loader-container">
        <div
          className="loader-progress-bar"
          style={{ width: progress + "%" }}
        />
      </div>
    </div>
  )
}

export default FakeLoader
